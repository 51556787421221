.contact {
  display: flex;
  flex-direction: row;
  gap: 8%;
}

@media screen and (max-width: 976px) {
  .contact {
    flex-direction: column;
  }
  .contact__form {
    margin-bottom: 30px;
  }
}

.contact__form {
  display: flex;
  width: 90%;
  flex-direction: column;
}

.contact__form input,
.contact__form textarea {
  outline: none;
  background-color: transparent;
  border: none;
  height: 25px;
  padding: 4px;
  color: white;
  font-size: 14px;
}

@media screen and (min-width: 663px) {
  .contact__form {
    width: 100%;
  }
  .form--group {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, auto);
  }
}

.contact__form textarea {
  resize: vertical;
  height: 80px;
  width: calc(100% - 10px);
}
.contact__form .form--group label {
  width: 100% !important;
}
.contact__form label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
}
.contact__form form {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact__form .base-link {
  margin-top: 10px;
}

.input--wrapper {
  position: relative;
  width: 100%;
  display: flex;
  margin: 5px 0px;
  color: rgb(62, 62, 62);
  border: 1px solid rgba(70, 70, 70);
  background-color: transparent;
}

.contact__form .fold {
  border-bottom: 1px solid rgba(70, 70, 70);
  width: 20px;
  height: 22px;
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 2;
  background: rgb(30, 30, 30);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.recaptcha {
  width: 200px;
}
