@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  background-color: #1e1e1e;
  margin: 0px;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.app {
  display: flex;
  max-height: 100vh;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .app {
    flex-direction: column;
  }
}

::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: #464646;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: rgb(142, 142, 142);
}
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background: rgb(103, 103, 103);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #1e1e1e inset; /* Change the color to your own background color */
  -webkit-text-fill-color: white;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #1e1e1e inset; /*your box-shadow*/
  -webkit-text-fill-color: white;
}

.text-base {
  font-size: 13px;
  line-height: 22px;
}
.text-base strong {
  font-weight: 600;
}

.text--sm {
  font-size: 12px;
  line-height: 20px;
}
.text--sm strong {
  font-weight: 600;
}

.subtitle--bg {
  color: #939598;
  font-size: 20px;
  margin: 10px 0px;
}

.subtitle--md {
  color: #939598;
  font-size: 17px;
  font-weight: 500;
  margin: 10px 0px;
}

.subtitle--sm {
  color: #939598;
  font-size: 16px;
  margin: 10px 0px;
}

.base-link {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 11px;
  color: #939598;
  text-decoration: none;
}
.base-link:hover {
  transition: all 0.5s;
  cursor: pointer;
  color: white;
}

.base-link:hover svg {
  transform: rotate(0deg);
}

.base-link svg {
  transition: all 0.5s;
  color: white !important;
}

/** SIDEBAR WRAPPER */
.sidebar {
  width: 12%;
  min-width: 190px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  border-right: 1px solid #3e3e3e;
}

/** SIDEBAR BLOCKS */
.sidebar__logo,
.sidebar__list,
.sidebar__footer {
  width: 100%;
  box-sizing: border-box;
  left: 0;
  transition: height 0.6s;
}

.sidebar__list {
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: space-between;
}

/** FOOTER */
.sidebar__footer {
  padding: 20px 0px;
}
.sidebar__footer::before {
  border-top: 1px solid #3e3e3e;
  content: "";
  width: 100%;
  position: absolute;
  margin-left: 0px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  left: 0px;
  float: left;
}
.sidebar__footer li h1 {
  margin-top: 17px !important;
  text-transform: inherit !important;
  color: #939598;
  font-size: 16px !important;
}
.sidebar__footer li + svg.arrow-bottom--md {
  position: absolute;
  margin-bottom: -15px !important;
  right: 10px;
  font-size: 16px;
  color: #939598;
}

/** LIST ITEM */
.sidebar li + li {
  margin-top: 36px !important;
}
.sidebar li {
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin: 0px;
  cursor: pointer;
  padding: 0px;
}
.sidebar li:hover span {
  color: #131313;
}
.sidebar li span {
  position: absolute;
  color: #292929;
  font-weight: 700;
  font-size: 54px;
  transition: all 0.5s;
  z-index: -1;
}
.sidebar li h1 {
  z-index: 1;
  margin-left: 5px;
  margin-top: 10px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
}

.sidebar__hamburguer {
  display: none;
  color: #1e1e1e;
  padding: 10px;
  margin-right: 20px;
  font-size: 20px;
  line-height: 17px;
  border-radius: 50%;
  cursor: pointer;
  background: #939598;
}
.sidebar button {
  border: none;
  display: none;
  background: none;
}
.sidebar__hamburguer:hover svg {
  transform: rotate(90deg);
  transition: all 0.5s;
}

@media screen and(min-width: 768px) {
  .sidebar__list,
  .sidebar__footer {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .sidebar button {
    display: block;
  }
  .sidebar {
    width: 100vw;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    border: 0px;
    border-bottom: 1px solid #3e3e3e;
  }
  .sidebar__logo {
    width: 180px;
  }
  .sidebar__hamburguer {
    display: block;
  }
  .sidebar__list {
    border-top: 1px solid #3e3e3e;
    width: 100%;
    z-index: 1000;
    margin: 0px;
    padding-top: 60px;
    top: 130px;
    background-color: #1e1e1e;
    height: calc(100vh - 130px);
    position: absolute;
  }

  .sidebar__list li {
    padding-left: 20px;
  }
  .sidebar__footer li {
    padding-top: 20px;
  }
  .sidebar__footer {
    width: 100%;
    padding: 0px;
    border: 0px;
    bottom: 0px;
  }
  .sidebar__list.disable {
    display: none !important;
    height: 0px;
  }
}

.home {
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;
  padding: 3%;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .home {
    width: auto;
  }
}

.section + .section {
  padding-top: 19vh;
}

svg.arrow-bottom--md {
  transform: rotate(-45deg);
}

.heading {
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin: 0px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0px;
}
.heading span {
  position: absolute;
  color: #292929;
  font-weight: 700;
  font-size: 90px;
  z-index: -1;
}
.heading .title--bg {
  z-index: 1;
  margin: 0px;
  margin-top: 3px;
  width: 100%;
  color: #939598;
  font-weight: 400;
  font-size: 32px;
  padding-left: 5px;
}

.heading .title--md {
  padding-left: 5px;
  z-index: 1;
  margin: 0px;
  margin-top: 5px;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  color: white;
}

.what-we-do {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 5%;
  gap: 5%;
  margin-top: 12vh;
}
@media screen and (max-width: 768px) {
  .what-we-do {
    flex-direction: column;
    margin-top: 10px;
  }
}

.what-we-do .cards {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(4, auto);
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
@media screen and (max-width: 980px) {
  .what-we-do .cards {
    grid-template-columns: repeat(2, auto);
  }
}

@media screen and (max-width: 768px) {
  .what-we-do .cards .card__image {
    width: 100%;
    min-height: 200px;
    height: 100%;
  }

  .what-we-do .cards .card__image img {
    width: 100%;
    min-height: 200px;
    height: 100%;
  }
}
@media screen and (max-width: 370px) {
  .what-we-do .cards {
    grid-template-columns: repeat(1, auto);
  }
  .what-we-do .cards .card__image {
    min-height: 300px;
  }

  .what-we-do .cards .card__image img {
    min-height: 300px;
  }
}

svg.arrow-bottom--sm {
  font-size: 14px;
  margin-top: 5px;
  transform: rotate(-45deg);
  color: #939598;
}

.what-we-do .cards .card {
  display: flex;
  flex-direction: column;
  position: relative;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.what-we-do .cards .card__image {
  height: 10vw;
  position: relative;
}

.what-we-do .cards .card__image img {
  position: relative;
  height: 10vw;
}

.what-we-do .cards .card__image svg {
  font-size: 70px;
  color: #939598;
  position: absolute;
  bottom: 0;
}

.what-we-do .card .base-link {
  margin-top: 20px;
}
.text-caption {
  font-size: 23px;
  margin: 10px 0px;
}
.text-caption strong {
  font-weight: 600;
}

.plan-news {
  display: flex;
  flex-direction: column;
}
.plan-news .header {
  display: flex;
  flex-direction: row;
  align-items: end;
  grid-gap: 20px;
  gap: 20px;
}
.plan-news .base-link {
  margin-bottom: 10px;
  line-height: 10px;
}

.plan-news .cards {
  display: grid;
  grid-template-columns: repeat(5, 19%);
  justify-content: space-between;
  margin-top: 3%;
  width: 100%;
}

.plan-news .cards .card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .plan-news .card {
    margin-top: 20px;
  }
  .plan-news .cards {
    grid-template-columns: repeat(1, auto);
  }
  .card__image .fold {
    border: 16vw solid;
    border-color: rgb(30, 30, 30) rgb(30, 30, 30) transparent transparent;
  }
}
@media screen and (min-width: 769px) {
  .card__image .fold {
    border: 30px solid;
    border-color: rgb(30, 30, 30) rgb(30, 30, 30) transparent transparent;
  }
}

.plan-news .cards .card .card__image {
  position: relative;
}

.plan-news .cards .card .card__image img {
  width: 100%;
  height: 100%;
}

.plan-news .cards .card .card__image .fold {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-color: rgb(30, 30, 30) rgb(30, 30, 30) transparent transparent;
}

.plan-news .cards .card .card__image .glasses {
  width: 15%;
  position: absolute;
  right: 0;
  z-index: 1;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.plan-news .cards .card .card__description {
  padding: 10px;
  height: 100%;
  background-color: rgb(42, 42, 42);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__description .text--sm {
  padding: 0;
  margin: 0px !important;
}

.card__description .subtitle--md {
  margin: 5px 0px;
}
.plan-news .cards .card .tag {
  padding: 4px;
  font-weight: 600;
  font-size: 12px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: rgb(42, 42, 42);
  background-color: rgb(150, 150, 150);
}

.contact {
  display: flex;
  flex-direction: row;
  grid-gap: 8%;
  gap: 8%;
}

@media screen and (max-width: 976px) {
  .contact {
    flex-direction: column;
  }
  .contact__form {
    margin-bottom: 30px;
  }
}

.contact__form {
  display: flex;
  width: 90%;
  flex-direction: column;
}

.contact__form input,
.contact__form textarea {
  outline: none;
  background-color: transparent;
  border: none;
  height: 25px;
  padding: 4px;
  color: white;
  font-size: 14px;
}

@media screen and (min-width: 663px) {
  .contact__form {
    width: 100%;
  }
  .form--group {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(2, auto);
  }
}

.contact__form textarea {
  resize: vertical;
  height: 80px;
  width: calc(100% - 10px);
}
.contact__form .form--group label {
  width: 100% !important;
}
.contact__form label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
}
.contact__form form {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.contact__form .base-link {
  margin-top: 10px;
}

.input--wrapper {
  position: relative;
  width: 100%;
  display: flex;
  margin: 5px 0px;
  color: rgb(62, 62, 62);
  border: 1px solid rgba(70, 70, 70);
  background-color: transparent;
}

.contact__form .fold {
  border-bottom: 1px solid rgba(70, 70, 70);
  width: 20px;
  height: 22px;
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 2;
  background: rgb(30, 30, 30);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.recaptcha {
  width: 200px;
}

.contact .cards {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(3, auto);
}

.contact .cards .card img {
  width: 50%;
  max-width: 130px;
}

.contact .text-base {
  margin-top: 20px;
}

.contact .cards .card .base-link {
  font-size: 13px;
}

.contact .cards .card .icons-wrapper {
  color: #1e1e1e;
}
@media screen and (max-width: 400px) {
  .contact .cards {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(1, auto);
  }
}
@media screen and (max-width: 1072) {
  .contact .cards {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(2, auto);
  }
}

.contact .cards .card .icons-wrapper svg {
  background-color: #939598;
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
}

.contact .cards .card .icons-wrapper svg:hover {
  cursor: pointer;
  background-color: #cbcbcb;
  transition: all 0.5s;
}

