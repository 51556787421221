@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background-color: #1e1e1e;
  margin: 0px;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.app {
  display: flex;
  max-height: 100vh;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .app {
    flex-direction: column;
  }
}

::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: #464646;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: rgb(142, 142, 142);
}
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background: rgb(103, 103, 103);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #1e1e1e inset; /* Change the color to your own background color */
  -webkit-text-fill-color: white;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #1e1e1e inset; /*your box-shadow*/
  -webkit-text-fill-color: white;
}

.text-base {
  font-size: 13px;
  line-height: 22px;
}
.text-base strong {
  font-weight: 600;
}

.text--sm {
  font-size: 12px;
  line-height: 20px;
}
.text--sm strong {
  font-weight: 600;
}

.subtitle--bg {
  color: #939598;
  font-size: 20px;
  margin: 10px 0px;
}

.subtitle--md {
  color: #939598;
  font-size: 17px;
  font-weight: 500;
  margin: 10px 0px;
}

.subtitle--sm {
  color: #939598;
  font-size: 16px;
  margin: 10px 0px;
}

.base-link {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 11px;
  color: #939598;
  text-decoration: none;
}
.base-link:hover {
  transition: all 0.5s;
  cursor: pointer;
  color: white;
}

.base-link:hover svg {
  transform: rotate(0deg);
}

.base-link svg {
  transition: all 0.5s;
  color: white !important;
}
