.what-we-do {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5%;
  margin-top: 12vh;
}
@media screen and (max-width: 768px) {
  .what-we-do {
    flex-direction: column;
    margin-top: 10px;
  }
}
