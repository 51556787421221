/** SIDEBAR WRAPPER */
.sidebar {
  width: 12%;
  min-width: 190px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  border-right: 1px solid #3e3e3e;
}

/** SIDEBAR BLOCKS */
.sidebar__logo,
.sidebar__list,
.sidebar__footer {
  width: 100%;
  box-sizing: border-box;
  left: 0;
  transition: height 0.6s;
}

.sidebar__list {
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: space-between;
}

/** FOOTER */
.sidebar__footer {
  padding: 20px 0px;
}
.sidebar__footer::before {
  border-top: 1px solid #3e3e3e;
  content: "";
  width: 100%;
  position: absolute;
  margin-left: 0px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  left: 0px;
  float: left;
}
.sidebar__footer li h1 {
  margin-top: 17px !important;
  text-transform: inherit !important;
  color: #939598;
  font-size: 16px !important;
}
.sidebar__footer li + svg.arrow-bottom--md {
  position: absolute;
  margin-bottom: -15px !important;
  right: 10px;
  font-size: 16px;
  color: #939598;
}

/** LIST ITEM */
.sidebar li + li {
  margin-top: 36px !important;
}
.sidebar li {
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin: 0px;
  cursor: pointer;
  padding: 0px;
}
.sidebar li:hover span {
  color: #131313;
}
.sidebar li span {
  position: absolute;
  color: #292929;
  font-weight: 700;
  font-size: 54px;
  transition: all 0.5s;
  z-index: -1;
}
.sidebar li h1 {
  z-index: 1;
  margin-left: 5px;
  margin-top: 10px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
}

.sidebar__hamburguer {
  display: none;
  color: #1e1e1e;
  padding: 10px;
  margin-right: 20px;
  font-size: 20px;
  line-height: 17px;
  border-radius: 50%;
  cursor: pointer;
  background: #939598;
}
.sidebar button {
  border: none;
  display: none;
  background: none;
}
.sidebar__hamburguer:hover svg {
  transform: rotate(90deg);
  transition: all 0.5s;
}

@media screen and(min-width: 768px) {
  .sidebar__list,
  .sidebar__footer {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .sidebar button {
    display: block;
  }
  .sidebar {
    width: 100vw;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    border: 0px;
    border-bottom: 1px solid #3e3e3e;
  }
  .sidebar__logo {
    width: 180px;
  }
  .sidebar__hamburguer {
    display: block;
  }
  .sidebar__list {
    border-top: 1px solid #3e3e3e;
    width: 100%;
    z-index: 1000;
    margin: 0px;
    padding-top: 60px;
    top: 130px;
    background-color: #1e1e1e;
    height: calc(100vh - 130px);
    position: absolute;
  }

  .sidebar__list li {
    padding-left: 20px;
  }
  .sidebar__footer li {
    padding-top: 20px;
  }
  .sidebar__footer {
    width: 100%;
    padding: 0px;
    border: 0px;
    bottom: 0px;
  }
  .sidebar__list.disable {
    display: none !important;
    height: 0px;
  }
}
