.plan-news .cards {
  display: grid;
  grid-template-columns: repeat(5, 19%);
  justify-content: space-between;
  margin-top: 3%;
  width: 100%;
}

.plan-news .cards .card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .plan-news .card {
    margin-top: 20px;
  }
  .plan-news .cards {
    grid-template-columns: repeat(1, auto);
  }
  .card__image .fold {
    border: 16vw solid;
    border-color: rgb(30, 30, 30) rgb(30, 30, 30) transparent transparent;
  }
}
@media screen and (min-width: 769px) {
  .card__image .fold {
    border: 30px solid;
    border-color: rgb(30, 30, 30) rgb(30, 30, 30) transparent transparent;
  }
}

.plan-news .cards .card .card__image {
  position: relative;
}

.plan-news .cards .card .card__image img {
  width: 100%;
  height: 100%;
}

.plan-news .cards .card .card__image .fold {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-color: rgb(30, 30, 30) rgb(30, 30, 30) transparent transparent;
}

.plan-news .cards .card .card__image .glasses {
  width: 15%;
  position: absolute;
  right: 0;
  z-index: 1;
  height: max-content;
}

.plan-news .cards .card .card__description {
  padding: 10px;
  height: 100%;
  background-color: rgb(42, 42, 42);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__description .text--sm {
  padding: 0;
  margin: 0px !important;
}

.card__description .subtitle--md {
  margin: 5px 0px;
}
.plan-news .cards .card .tag {
  padding: 4px;
  font-weight: 600;
  font-size: 12px;
  width: max-content;
  color: rgb(42, 42, 42);
  background-color: rgb(150, 150, 150);
}
