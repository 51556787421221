.contact .cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, auto);
}

.contact .cards .card img {
  width: 50%;
  max-width: 130px;
}

.contact .text-base {
  margin-top: 20px;
}

.contact .cards .card .base-link {
  font-size: 13px;
}

.contact .cards .card .icons-wrapper {
  color: #1e1e1e;
}
@media screen and (max-width: 400px) {
  .contact .cards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, auto);
  }
}
@media screen and (max-width: 1072) {
  .contact .cards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, auto);
  }
}

.contact .cards .card .icons-wrapper svg {
  background-color: #939598;
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
}

.contact .cards .card .icons-wrapper svg:hover {
  cursor: pointer;
  background-color: #cbcbcb;
  transition: all 0.5s;
}
