.home {
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;
  padding: 3%;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .home {
    width: auto;
  }
}

.section + .section {
  padding-top: 19vh;
}

svg.arrow-bottom--md {
  transform: rotate(-45deg);
}

.heading {
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin: 0px;
  width: max-content;
  padding: 0px;
}
.heading span {
  position: absolute;
  color: #292929;
  font-weight: 700;
  font-size: 90px;
  z-index: -1;
}
.heading .title--bg {
  z-index: 1;
  margin: 0px;
  margin-top: 3px;
  width: 100%;
  color: #939598;
  font-weight: 400;
  font-size: 32px;
  padding-left: 5px;
}

.heading .title--md {
  padding-left: 5px;
  z-index: 1;
  margin: 0px;
  margin-top: 5px;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  color: white;
}
