.what-we-do .cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, auto);
  height: max-content;
}
@media screen and (max-width: 980px) {
  .what-we-do .cards {
    grid-template-columns: repeat(2, auto);
  }
}

@media screen and (max-width: 768px) {
  .what-we-do .cards .card__image {
    width: 100%;
    min-height: 200px;
    height: 100%;
  }

  .what-we-do .cards .card__image img {
    width: 100%;
    min-height: 200px;
    height: 100%;
  }
}
@media screen and (max-width: 370px) {
  .what-we-do .cards {
    grid-template-columns: repeat(1, auto);
  }
  .what-we-do .cards .card__image {
    min-height: 300px;
  }

  .what-we-do .cards .card__image img {
    min-height: 300px;
  }
}

svg.arrow-bottom--sm {
  font-size: 14px;
  margin-top: 5px;
  transform: rotate(-45deg);
  color: #939598;
}

.what-we-do .cards .card {
  display: flex;
  flex-direction: column;
  position: relative;
  height: max-content;
}

.what-we-do .cards .card__image {
  height: 10vw;
  position: relative;
}

.what-we-do .cards .card__image img {
  position: relative;
  height: 10vw;
}

.what-we-do .cards .card__image svg {
  font-size: 70px;
  color: #939598;
  position: absolute;
  bottom: 0;
}

.what-we-do .card .base-link {
  margin-top: 20px;
}
.text-caption {
  font-size: 23px;
  margin: 10px 0px;
}
.text-caption strong {
  font-weight: 600;
}
