.plan-news {
  display: flex;
  flex-direction: column;
}
.plan-news .header {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 20px;
}
.plan-news .base-link {
  margin-bottom: 10px;
  line-height: 10px;
}
